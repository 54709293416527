<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="left">
        <el-form inline class="search-header" :model="searchData" label-width="100px">
          <el-form-item label="姓名">
            <el-input style="width:300px" v-model="searchData.memberName" placeholder="请输入姓名" size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input style="width:300px" v-model="searchData.mobile" placeholder="请输入手机号" size="small"></el-input>
          </el-form-item>
          <el-form-item label="当前状态" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.status" clearable placeholder="请选择" size="small">
              <el-option v-for="item in CURRENT_STATUS" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="未付天数" v-show="isShow">
            <el-input-number
                    size="small"
                    style="width:138px"
                    v-model="searchData.leftOverdueDays"
                    :step="1"
                    :min="0"
                    step-strictly
                    controls-position="right"
            ></el-input-number>
            <span style="width:10px"> 至 </span>
            <el-input-number
                    size="small"
                    style="width:138px"
                    v-model="searchData.rightOverdueDays"
                    :step="1"
                    :min="0"
                    step-strictly
                    controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="平台" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.sysCode" clearable placeholder="请选择" size="small">
              <el-option v-for="item in sysList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户经理" v-show="isShow">
            <el-select size="small" style="width:300px" v-model="searchData.userName" filterable clearable placeholder="请选择">
              <el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.userName"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item v-show="isShow"> </el-form-item> -->

          <!-- <el-form-item class="arrow" label=" ">
            <el-button @click="getList" type="primary" icon="el-icon-search" size="small">查询</el-button>
            <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
            <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
            <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="right">
        <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
        <el-button style="margin-left:10px" @click="getList" type="primary" icon="el-icon-search" size="small">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <!-- <div class="btn-group">
      <div></div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'CustomerAddEdit' })">添加</el-button>
      </div>
    </div> -->
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
  </div>
</template>

<script>
import { CURRENT_STATUS, getDictEnumListAPI } from '@/enum/dict.js'
import { getListAPI, getAdminUserList } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '平台',
    prop: 'sysName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['sysName']}</p>
    }
  },
  {
    label: '客户经理',
    prop: 'userName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['user']['userName'] || ''}</p>
    }
  },
  {
    label: '客户姓名',
    prop: 'memberName',
    minWidth: '100',
    customRender(h, row) {
      return (
        <p class="small-size" title={row['memberName']}>
          {row['memberName']}
        </p>
      )
    }
  },
  {
    label: '手机号',
    prop: 'mobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['mobile']}</p>
    }
  },
  {
    label: '当前状态',
    prop: 'status',
    minWidth: '120',
    customRender(h, row) {
      return (
        <p>
          <el-link v-show={row['status'] == 1} underline={false} type="primary">
            正常
          </el-link>
          <el-link v-show={row['status'] == 2} underline={false} type="danger">
            逾期
          </el-link>
        </p>
      )
    }
  },
  {
    label: '未付天数',
    prop: 'overdueDays',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['overdueDays'] <= -1 ? '无' : (row['overdueDays'] == 0 ? '未逾期' : row['overdueDays'] + '天')}</p>
    }
  },
  {
    label: '在逾金额',
    prop: 'overdueAmount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['overdueAmount']}</p>
    }
  },
  {
    label: '最新备注',
    prop: 'remark',
    minWidth: '150',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['remark']}>
          {row['remark']}
        </p>
      )
    }
  },
  {
    label: '进行中的订单',
    prop: 'progressOrderNum',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['progressOrderNum']}</p>
    }
  },
  {
    label: '已完成的订单',
    prop: 'finishOrderNum',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['finishOrderNum']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'sysCreateTime',
    minWidth: '170',
    customRender(h, row) {
      return (
        <p class="small-size" title={row['sysCreateTime']}>
          {row['sysCreateTime']}
        </p>
      )
    }
  },
  {
    label: '操作',
    width: '180',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn(this.AUTH_BTN.customer_order_list)}
            underline={false}
            type="primary"
            onClick={() => {
              let link = this.$router.resolve({ path: `/order/list`, query: { memberId: row['id'] } })
              window.open(link.href, "_blank")
            }}
          >
            查看订单
          </el-link>

          <el-link
            style="margin-left:10px"
            v-show={this.isShowBtn(this.AUTH_BTN.customer_remark_list)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'CustomerRemarkList', params: { memberId: row['id'] } })}
          >
            备注列表
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  mixins: [authBtnMixin],

  data() {
    return {
      isShow: false, //控制箭头
      columns,
      currentPage: 1,
      pageSize: 50,
      total: 0,
      list: [],
      sysList: [],
      userList: [],
      searchData: {
        memberName: '',
        mobile: '',
        status: null,
        sysCode: '',
        userName: '',
        leftOverdueDays: undefined,
        rightOverdueDays: undefined,
      },
      CURRENT_STATUS
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
    this.getDictEnumList()
    this.getAdminUserList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { memberName, mobile, status, sysCode, userName, leftOverdueDays, rightOverdueDays } = this.searchData
      const params = {
        memberName,
        mobile,
        status,
        sysCode,
        userName,
        leftOverdueDays,
        rightOverdueDays,
        page: this.currentPage,
        pageSize: this.pageSize
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    async getDictEnumList() {
      this.sysList = await getDictEnumListAPI('identity:sys')
    },
    async getAdminUserList() {
      const res = await getAdminUserList({})
      this.userList = res || []
    },
    handleDel(id) {},
    handleCommand(command) {
      let { row, type } = command
      switch (type) {
        case 'detail':
          this.$router.push({ name: 'CustomerDetail', params: { id: row['id'] } })
          break
        case 'operationLog':
          this.$router.push({ name: 'CustomerRemarkList', params: { id: row['id'] } })
          break

        default:
          break
      }
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  },
  activated() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      flex: 1;
    }
    .right {
      margin-left: 20px;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
  }
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      // text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
  }
  .el-divider {
    margin: 10px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .el-form {
    // min-width: 1200px;
    // .el-form-item {
    //   width: 20%;
    //   min-width: 280px;
    //   margin-bottom: 0;
    // }
    .el-form-item {
      width: 40%;
      min-width: 400px;
      margin-bottom: 0;
    }
  }
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}
</style>
